import {Box, Container, Divider, Stack, Typography} from "@mui/material";
import {description, img_url, title} from "../data";
import React from "react";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import RedeemIcon from '@mui/icons-material/Redeem';
function Main() {
    const navigate = useNavigate();
    return (
        <Container
            sx={{
                display: 'flex',
                width: '100vw',
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <Stack direction="row" sx={{
                width: '900px',
                height: '552px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                boxShadow: '5px 5px 8px 5px #cccccc',
                borderRadius: '15px',
                background: `
                    linear-gradient(270deg, rgba(255, 255, 255, 0.7) 43.44%, rgba(255, 255, 255, 0.18) 100%),
                    url("${img_url}") no-repeat center center
                `,
                backgroundSize: 'cover',
                '@media (max-width: 600px)': {
                    width: '100vw',
                    height: '100vh',
                    borderRadius: '0',
                    boxShadow: 'none',
                },
            }}>
                <Box sx={{
                    width: '100%',
                    '@media (max-width: 600px)': {
                        display: 'none',
                    },
                }}></Box>
                <Divider
                    orientation="vertical"
                    sx={{
                        borderRadius: '3px',
                        borderColor: '#84878a',
                        '@media (max-width: 600px)': {
                            display: 'none',
                        },
                    }}
                />
                <Box sx={{ width: '100%' }}>
                    <Stack direction="column">
                        <Typography sx={{
                            fontSize: '24px',
                            textTransform: 'uppercase',
                            fontFamily: 'Mulish, Montserrat, sans-serif',
                            fontWeight: 700,
                            '@media (max-width: 600px)': {
                                fontSize: '20px',
                            },
                        }}>{title}</Typography>
                        <Typography sx={{
                            fontSize: '16px',
                            fontFamily: 'Mulish, Montserrat, sans-serif',
                            fontWeight: 300,
                            marginTop: '20px',
                            '@media (max-width: 600px)': {
                                fontSize: '14px',
                                marginTop: '10px',
                            },
                        }}>{description}</Typography>
                        <Button variant='outlined'
                                startIcon=
                                    {<RedeemIcon
                                        sx={{
                                            color:'#84878a',
                                            marginLeft: '10px',
                                            width: 50,
                                            height:50
                                        }}/>}
                                disabled sx={{
                            width: '60%',
                            height: '60px',
                            marginLeft: '10%',
                            marginTop: '20px',
                            justifyContent: 'left',
                        }}>
                            <Typography sx={{
                            fontSize: '22px',
                            fontFamily: 'Mulish, Montserrat, sans-serif',
                            fontWeight: 500,
                            color: '#84878a',
                            '@media (max-width: 600px)': {
                                fontSize: '20px',
                            },
                        }}>{'25%'}</Typography>
                        </Button>
                        <Button variant="contained"
                                sx={{
                                    color: '#fff',
                                    backgroundColor: '#5f9b8c',
                                    padding: '20px 16px',
                                    boxShadow: '0px 0px 0px #fff',
                                    maxWidth: '250px',
                                    '&:hover': {
                                        boxShadow: '0px 0px 0px #fff',
                                        backgroundColor: '#5f9b8c',
                                        borderColor: 'transparent',
                                    },
                                    marginTop: '40px',
                                    marginRight: '25%',
                                    marginLeft: '25%',
                                    '@media (max-width: 600px)': {
                                        padding: '15px 12px',
                                        marginTop: '20px',
                                        maxWidth: '300px',
                                    },
                                }}
                                onClick={() => {
                                    navigate('/question-1')
                                }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '20px',
                                    textTransform: 'uppercase',
                                    fontFamily: 'Mulish, Montserrat, sans-serif',
                                    fontWeight: 700,
                                    '@media (max-width: 600px)': {
                                        fontSize: '18px',
                                    },
                                }}
                            >Почати</Typography>
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </Container>
    );
}


export default Main;
