import {useNavigate} from "react-router-dom";
import {Container, Typography} from "@mui/material";
import CenterBlock from "../components/CenterBlock";
import Button from "@mui/material/Button";
import React, {useContext, useEffect, useState} from "react";
import {QuizContext} from "./RootLayout";

function Results() {
    const navigate = useNavigate();
    const {quizState} = useContext(QuizContext);

    const [resultText, setResultText] = useState('Loading...');

    useEffect(() => {
        if (quizState) {
            if (quizState.state === false) {
                navigate('/');
            }
            if (quizState.points < 7){
                setResultText('Ступінь ризику: мінімальний. ' +
                    'Ви вмієте так організувати своє життя, що проблеми зі ШКТ вас обходять стороною. ' +
                    'Правильне харчування, регулярне фізичне навантаження, ' +
                    'вміння справлятися зі стресами - ось три складові вашого успіху!.');
            }
            else if (quizState.points < 13){
                setResultText('Ступінь ризику: середній. ' +
                    'Будьте уважні до симптомів, які можуть свідчити про проблеми зі ШКТ: печія, діарея, біль у шлунку. ' +
                    'Відмовтеся від солодощів і жирної їжі, не вживайте каву, алкоголь, припиніть курити. ' +
                    'Чудовою профілактикою захворювань ШКТ і порушень травлення також є вміння ефективно боротися зі стресами, ' +
                    'правильно заспокоїти свою нервову систему. ' +
                    'Рекомендуємо звернутися до лікаря гастроентеролога або терапевта. ');
            }
            else {
                setResultText('Ступінь ризику: високий. ' +
                    'Вам необхідно обов\'язково відвідати гастроентеролога, терапевта або проктолога: цілком ймовірно, ' +
                    'що ви вже запустили хворобу. ' +
                    'Варто переглянути режим харчування, не захоплюватися дієтами і прийомом ліків, особливо антибіотиків.');
            }

        }
    }, [quizState]);

    return (
        <Container
            sx={{display: 'flex', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
            <CenterBlock>
                <Typography sx={{
                    fontSize: '24px',
                    textTransform: 'uppercase',
                    fontFamily: 'Mulish, Montserrat, sans-serif',
                    fontWeight: 700,
                }}>Результати тесту:</Typography>
                <Typography sx={{
                    fontSize: '18px',
                    fontFamily: 'Mulish, Montserrat, sans-serif',
                    fontWeight: 400,
                    marginTop: '20px',
                    marginLeft: '2opx',
                    marginRight: '20px',
                }}>{resultText}</Typography>

                <Button
                    variant="contained"
                    sx={{
                        color: '#fff',
                        backgroundColor: '#5f9b8c',
                        padding: '20px 16px',
                        boxShadow: '0px 0px 0px #fff',
                        maxWidth: '250px',
                        '&:hover': {
                            boxShadow: '0px 0px 0px #fff',
                            backgroundColor: '#5f9b8c',
                            borderColor: 'transparent',
                        },
                        marginTop: '30px',
                        '@media (max-width: 600px)': {
                            width: '300px',
                            height: '10vh',
                        },
                    }}
                    onClick={() => {
                        window.open('https://www.instagram.com/dr_paramonovaaa?igsh=NHE3NWVxZ3RxbG1x', '_blank');
                    }}
                >Завершити тест</Button>
            </CenterBlock>
        </Container>
    )
}

export default Results;
