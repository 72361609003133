export const title = 'ПЕРЕВІР СТАН СВОЄЇ ТРАВНОЇ СИСТЕМИ ЗА 5 ХВИЛИН';
export const description = 'Простий тест, який допоможе з’ясувати, чи в нормі ваш шлунково-кишковий тракт. ' +
    'В кінці квізу ви отримаєте знижку на первинну консультацію гастроентеролога.';
export const img_url = 'https://i.pinimg.com/originals/68/a2/0b/68a20b77066a3e0b6ec32a468a6907b6.jpg';
export const present_img_url = 'https://api.adsquiz.io/storage/06y6hnBhesorIPPHqLVvEm4blMgv5AMLG7N3pVwU.png';

export const questions = [
    {
        question: "Дуже часто злоякісні новооутворення органів черевної порожнини симптоматично не проявляються до 3-4 стадії, коли вже вилікувати їх дуже важко. Тому рекомендується профілактично проходити УЗД органів черевної порожнини, хоча б раз на рік. \n" +
            "\n Коли останній раз Ви проходили обстеження черевної порожнини?",
        answers: [
            { text: "більше 3 років тому", points: 2 },
            { text: "протягом останніх декілька років", points: 1 },
            { text: "протягом останнього року", points: 0 }
        ]
    },
    {
        question: "Дуже багато важливих гормонів для правильного функціонування травної системи виробляються вночі. Тому важливо лягати спати не пізніше 23:00 і вставати не пізніше 07:00. \n" +
            "Як часто Ви притримуєтесь такого графіку сну?",
        answers: [
            { text: "ніколи", points: 2 },
            { text: "декілька разів на тиждень", points: 1 },
            { text: "постійно", points: 0 }
        ]
    },
    {
        question: "Чи прокидаєтесь Ви вночі від позивів в туалет (по-великому), чи від болю (дискомфорту) в животі?    ",
        answers: [
            { text: "так, часто", points: 2 },
            { text: "дуже рідко (наприклад, при харчовому отруєнні)", points: 1 },
            { text: "ніколи", points: 0 }
        ]
    },
    {
        question: "Чи буває у вас, що під час хвилювання на шкірі (обличчі чи тілі) з’являються яскраво-рожеві плями, які самостійно проходять?",
        answers: [
            { text: "Так, постійно", points: 2 },
            { text: "дуже рідко", points: 1 },
            { text: "Ніколи", points: 0 }
        ]
    },
    {
        question: "Зараз дуже популярні різноманітні дієти для схуднення. Чи приходилось вам використовувати якусь з них?",
        answers: [
            { text: "так, неодноразово", points: 2 },
            { text: "1-2 рази", points: 1 },
            { text: "ніколи", points: 0 }
        ]
    },
    {
        question: "Чи буває у вас так, що під час переживання чи стресу ви частіше, ніж зазвичай ходите в туалет «по-великому»?",
        answers: [
            { text: "так, постійно", points: 2 },
            { text: "інколи, але мене це не турбує", points: 1 },
            { text: "ніколи", points: 0 }
        ]
    },
    {
        question: "Деяким людям дуже часто після основного прийому їжі або після стресу хочеться з’їсти більше солодкого, ніж зазвичай. Чи буває у вас таке?",
        answers: [
            { text: "так, майже постійно", points: 2 },
            { text: "періодично", points: 1 },
            { text: "ніколи", points: 0 }
        ]
    },
    {
        question: "Норма частоти дефекації (процес виділення калових мас)- від 1 разу на 3 дні до 3 разів на день, при умові, що стілець сформований і сам процес безболісний. Чи співпадає ваш «графік» дефекації з цим твердженням?",
        answers: [
            { text: "ні, в мене рідше або частіше / маю певний біль або дискомфорт", points: 2 },
            { text: "рідко маю зміни", points: 1 },
            { text: "так, повністю співпадає", points: 0 }
        ]
    },
    {
        question: "Як часто ви снідаєте протягом години після пробудження?",
        answers: [
            { text: "ніколи", points: 2 },
            { text: "інколи (наприклад, декілька разів на тиждень)", points: 1 },
            { text: "постійно", points: 0 }
        ]
    },
    {
        question: " Багато жінок часто приймають знеболюючі препарати під час менструального болю. Чи часто ви в своєму житті використовуєте такі препарати? Можливо від головного або зубного болю? ",
        answers: [
            { text: "більше 5 разів на місяць", points: 2 },
            { text: "1-2 рази на місяць", points: 1 },
            { text: "дуже рідко", points: 0 }
        ]
    },
    {
        question: "\n" +
            " Як часто ви відчуваєте печію, гіркоту в роті, або інший неприємний смак чи запах з рота? (можливо щось одне)",
        answers: [
            { text: "майже постійно", points: 2 },
            { text: "досить часто (більше 2 разів на тиждень)", points: 1 },
            { text: "дуже рідко", points: 0 }
        ]
    },
    {
        question: "\n" +
            " Зараз часто стали використовувати медикаментозний сон під час ендоскопії (наприклад, колоноскопії). Тому багато пацієнтів спокійно, без страху і болю проходять цю процедуру. Чи робили ви колись гастро- або колоноскопію?",
        answers: [
            { text: "так, більше 3 разів", points: 2 },
            { text: "1-2 рази", points: 1 },
            { text: "ніколи", points: 0 }
        ]
    },
    {
        question: "Чи буває у вас таке, що кал має дуже неприємний та зловонний запах? Або ви помічали зміну кольору калу на зелений/рудий/безкольоровий/чорний тощо? ",
        answers: [
            { text: "так, часто", points: 2 },
            { text: "було 1-2 рази", points: 1 },
            { text: "ніколи", points: 0 }
        ]
    },
    {
        question: "Як часто ви з їжею споживаєте клітковину (крупи, цільнозернові макарони та хліб, насіння, овочі, фрукти)?",
        answers: [
            { text: "рідше, ніж 2 рази на тиждень", points: 2 },
            { text: "майже кожного дня", points: 1 },
            { text: "с кожним прийомом їжі", points: 0 }
        ]
    },
    {
        question: "Як часто ви відчуваєте спрагу протягом дня, чи багато вам хочеться пити?",
        answers: [
            { text: "так, випиваю більше 3 л рідини за день", points: 2 },
            { text: "рідко, випиваю до 1 л рідини в день", points: 1 },
            { text: "без особливостей, випиваю 1.5-2 л рідини за день", points: 0 }
        ]
    }
];
