import { Stack } from "@mui/material";

function CenterBlock({ children }) {
    return (
        <Stack direction="column" sx={{
            width: '900px',
            height: '395px',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            textAlign: 'center',
            boxShadow: '5px 5px 8px 5px #cccccc',
            borderRadius: '15px',
            backgroundColor: '#e5f5ed',
            '@media (max-width: 600px)': {
                width: '100vw',
                height: '100vh',
                borderRadius: '0',
                boxShadow: 'none',
                justifyContent: 'center',
            },
        }}>
            {children}
        </Stack>
    );
}

export default CenterBlock;
