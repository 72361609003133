import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#5f9c8c', // Вторичный цвет
        },
        // text: {
        //     primary: '#F2ECFF',
        //     disabled: '#F2ECFF',
        //     // secondary: '#08f500',
        // },
        // background: {
        //     default: '#1F2833',
        // },
    },
});

export default theme;
