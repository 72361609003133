import {useParams} from "react-router-dom";
import {questions} from "../data";
import QuestionBlock from "../components/QuestionBlock";
import {Container} from "@mui/material";
import {QuizContext} from "./RootLayout";
import {useContext} from "react";
import { useNavigate } from 'react-router-dom';

function Question() {
    const {question_ident} = useParams();
    const navigate = useNavigate();

    const question_number = Number.parseInt(question_ident.replace('question-', ''));
    const question_data = questions[question_number - 1];
    const { question, answers } = question_data;

    const {quizState, setQuizState } = useContext(QuizContext);

    function onAnswerDone(answerPoints) {
        const new_state = {...quizState, points: quizState.points + answerPoints};
        const nextNumber = question_number+1;
        if (nextNumber > questions.length){
            new_state.state = true;
            setQuizState(new_state);
            navigate('/test-done');
        }
        else {
            setQuizState(new_state);
            navigate(`/question-${nextNumber}`);
        }
    }

    return (
        <Container sx={{
            display: 'flex',
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <QuestionBlock
                question={question}
                answers={answers}
                number={question_number}
                total_numbers={questions.length}
                onAnswerDone={onAnswerDone}
            />
        </Container>
    )
}

export default Question;
