import {Outlet} from "react-router-dom";
import {createContext, Suspense, useState} from "react";

export const QuizContext = createContext({state: false, points: 0});

function RootLayout() {
    const [quizState, setQuizState] = useState({state: false, points: 0});

    const contextValue = {
        quizState,
        setQuizState,
    };

    return (
        <QuizContext.Provider value={contextValue}>
            <Suspense fallback={<h1>Loading...</h1>}>
                <Outlet />
            </Suspense>
        </QuizContext.Provider>
    );
}

export default RootLayout;
