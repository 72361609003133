import React from 'react';
import Button from '@mui/material/Button';

function CustomButton({ answer, onAnswerDone }) {
    return (
        <Button
            variant="outlined"
            sx={{
                borderColor: '#cccccc',
                width: '70%',
                height: '8vh',
                '&:hover': {
                    boxShadow: '4px 4px 8px #5f9c8c',
                    backgroundColor: 'transparent',
                    borderColor: '#cccccc',
                },
                '@media (max-width: 600px)': {
                    width: '300px',
                    height: '10vh'
                },
            }}
            onClick={() => {
                if (onAnswerDone) onAnswerDone(answer.points);
            }}
        >
            {answer.text}
        </Button>
    );
}

export default CustomButton;
