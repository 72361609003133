import { Grid, LinearProgress, Typography } from "@mui/material";
import CustomButton from "./CustomButton";
import CenterBlock from "./CenterBlock";

function QuestionBlock({ number, question, answers, total_numbers, onAnswerDone }) {
    return (
        <CenterBlock>
            <LinearProgress
                color="secondary"
                sx={{
                    width: '300px',
                    backgroundColor: '#cccccc',
                    border: '3px solid #cccccc',
                    borderRadius: '6px',
                    '@media (max-width: 600px)': {
                        width: '80%',
                    },
                }}
                variant="determinate"
                value={(number / total_numbers) * 100}
            />
            <Typography sx={{
                fontSize: '20px',
                textTransform: 'uppercase',
                fontFamily: 'Mulish, Montserrat, sans-serif',
                fontWeight: 700,
                '@media (max-width: 600px)': {
                    fontSize: '16px',
                    marginTop: '60px',
                    marginBottom: '60px'
                },
            }}>
                {number}. {question}
            </Typography>
            <Grid container spacing={2} sx={{
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
            }}>
                {answers.map((answer, index) => (
                    <Grid item xs={3} sm={4} key={index} sx={{
                        '@media (max-width: 600px)': {
                            width: '100%',
                            marginBottom: '10px',
                            marginRight: '50%'
                        },
                    }}>
                        <CustomButton answer={answer} onAnswerDone={() => {
                            if (onAnswerDone) onAnswerDone(answer.points);
                        }} />
                    </Grid>
                ))}
            </Grid>
        </CenterBlock>
    );
}

export default QuestionBlock;