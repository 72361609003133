import {Container, IconButton, Stack, Typography} from "@mui/material";
import React from "react";
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import CenterBlock from "../components/CenterBlock";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

function TestDone() {
    const navigate = useNavigate();

    return (
        <Container
            sx={{display: 'flex', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
            <CenterBlock>
                <Typography sx={{
                    fontSize: '24px',
                    textTransform: 'uppercase',
                    fontFamily: 'Mulish, Montserrat, sans-serif',
                    fontWeight: 700,
                }}>ТЕСТ УСПІШНО ПРОЙДЕНО!</Typography>
                <Typography sx={{
                    fontSize: '18px',
                    fontFamily: 'Mulish, Montserrat, sans-serif',
                    fontWeight: 400,
                    marginTop: '20px'
                }}>Ви отримали знижку 25% на первинну консультацію лікаря-гастроентеролога (при записі треба вказати, що ви пройшли тест).</Typography>
                <Button variant="contained"
                        sx={{
                            color: '#fff',
                            backgroundColor: '#5f9b8c',
                            padding: '20px 16px',
                            boxShadow: '0px 0px 0px #fff',
                            maxWidth: '250px',
                            '&:hover': {
                                boxShadow: '0px 0px 0px #fff',
                                backgroundColor: '#5f9b8c',
                                borderColor: 'transparent',
                            },
                            marginTop: '40px',
                            '@media (max-width: 600px)': {
                                width: '300px',
                                height: '10vh',
                            },
                        }}
                        onClick={() => {navigate('/results')}}>Отримати результат</Button>
                <Stack direction="row" sx={{width: '100%', paddingRight: '100px'}} justifyContent="right" alignItems="center">
                    <IconButton onClick={() => {
                        window.open('https://t.me/dr_paramonova', '_blank');
                    }}>
                        <TelegramIcon />
                    </IconButton>
                    <IconButton onClick={() => {
                        window.open('https://www.instagram.com/dr_paramonovaaa?igsh=NHE3NWVxZ3RxbG1x', '_blank');
                    }}>
                        <InstagramIcon />
                    </IconButton>
                </Stack>
            </CenterBlock>
        </Container>
    )
}

export default TestDone;
