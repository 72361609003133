import theme from "./Theme";
import {createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import Main from "./pages/Main";
import Question from "./pages/Question";
import RootLayout from "./pages/RootLayout";
import {SnackbarProvider} from "notistack";
import TestDone from "./pages/TestDone";
import Results from "./pages/Results";


function App() {
  const router = createBrowserRouter(
      createRoutesFromElements(
          <Route path="/" element={<RootLayout/>}>
              <Route index element={<Main />}/>
              <Route path="/test-done" element={<TestDone />} />
              <Route path="/results" element={<Results />} />
              <Route path=":question_ident" element={<Question/>}/>
              <Route path="*" element={<Navigate to={'/'}/>}/>
          </Route>
      )
  );

  return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <RouterProvider router={router}/>
        </SnackbarProvider>
      </ThemeProvider>
  );
}

export default App;
